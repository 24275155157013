<template>
  <div class="reports">
    <div class="card-subtitle mb-3">Отчет Агента за 2020 год</div>
    <div class="my-1">
      <form @submit.stop.prevent="submitForm">
        <div class="row">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <label class="text-muted">за период</label>
            <date-picker v-model="dateRange" locale="ru" :is-range="true">
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="input-group">
                      <div class="input-group-append">
                        <label for="agent-report-date-from" class="input-group-text">с</label>
                      </div>
                      <input class="form-control"
                             :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                             :value="inputValue.start"
                             v-on="inputEvents.start"
                             id="agent-report-date-from"
                             autocomplete="off"
                             required>
                      <div class="input-group-append">
                        <label for="agent-report-date-from" class="input-group-text">
                          <i class="bi-calendar-date"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <div class="input-group">
                      <div class="input-group-append">
                        <label for="agent-report-date-to" class="input-group-text">по</label>
                      </div>
                      <input class="form-control"
                             :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                             :value="inputValue.end"
                             v-on="inputEvents.end"
                             id="agent-report-date-to"
                             autocomplete="off"
                             required>
                      <div class="input-group-append">
                        <label for="agent-report-date-to" class="input-group-text">
                          <i class="bi-calendar-date"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </date-picker>
          </div>
          <div class="col-12 col-md-4 col-lg-3 mt-2 mt-md-0 text-right text-md-left">
            <label class="d-none d-md-block">&nbsp;</label>
            <button type="submit" class="btn btn-outline-success">
              Заказать
            </button>
            <a v-if="reportUrl" class="btn btn-outline-info ml-3" :href="reportUrl" target="_blank">
              <i class="bi-download"></i>
              Скачать
            </a>
          </div>
        </div>
      </form>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { Processing } from "@/components/common";
import { apiClientConfig } from "@/configs";
import { createReportManager } from "@/utils/di";
import moment from "moment";
import { DatePicker } from "v-calendar";

export default {
  name: "AgentReport2020",
  components: {
    DatePicker,
    Processing
  },
  data() {
    return {
      dateRange: {
        start: new Date("2020-01-01"),
        end: new Date("2020-12-31")
      },
      reportUrl: null,
      processing: false
    };
  },
  methods: {
    async submitForm() {
      this.processing = true;
      this.reportUrl = null;
      try {
        const manager = await createReportManager();
        const urlResult = await manager.exportAgentReport2020({
          dateFrom: moment(this.dateRange.start).format("YYYY-MM-DD"),
          dateTo: moment(this.dateRange.end).format("YYYY-MM-DD")
        });
        this.reportUrl = apiClientConfig.baseUrl + urlResult.url;
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>
