<template>
  <div class="my-documents">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header">
          <div class="card-title">Мои документы</div>
        </div>
        <div class="card-body">
          <common-documents></common-documents>
        </div>
        <div v-if="reportsFeature" class="card-body">
          <agent-report></agent-report>
        </div>
        <div v-if="reportsFeature" class="card-body">
          <agent-report2020></agent-report2020>
        </div>
<!--        <div v-if="reportsFeature" class="card-body">-->
<!--          <ndfl-report></ndfl-report>-->
<!--        </div>-->
        <div class="card-body">
          <project-documents></project-documents>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appConfig } from "@/configs";
import AgentReport from "./components/AgentReport";
import AgentReport2020 from "./components/AgentReport2020";
import CommonDocuments from "./components/CommonDocuments";
import NdflReport from "./components/NdflReport";
import ProjectDocuments from "./components/ProjectDocuments";

export default {
  name: "MyDocuments",
  components: {
    AgentReport,
    AgentReport2020,
    CommonDocuments,
    NdflReport,
    ProjectDocuments
  },
  data() {
    return {
      reportsFeature: appConfig.reportsFeature || localStorage.getItem("reportsFeature")
    };
  },
}
</script>

<style lang="scss">
.my-documents {
  .card {
    .card-body {
      min-height: 8rem;

      .download-link {
        a {
          position: relative;
          padding-left: 1.25rem;
          display: inline-block;

          .bi-file-arrow-down:before {
            position: absolute;
            margin: 0.25rem 0 0 -1.25rem;
          }
        }
      }
    }

    .card-body:nth-child(2) {
      border-top: none;
    }
  }
}
</style>
