<template>
  <div class="reports">
    <div class="card-subtitle mb-3">Отчет НДФЛ</div>
    <div class="my-1">
      <form @submit.stop.prevent="submitForm">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <label class="text-muted">за период</label>
            <div class="input-group">
              <select class="form-control"
                     id="agent-report-date-from"
                     autocomplete="off"
                     required
                      @change="handleSelect"
              >
                <option v-for="y in years">{{ y }}</option>
              </select>
              <div class="input-group-append">
                <label for="agent-report-date-from" class="input-group-text">
                  <i class="bi-calendar-date"></i>
                </label>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3 mt-2 mt-md-0 text-right text-md-left">
            <label class="d-none d-md-block">&nbsp;</label>
            <button type="submit" class="btn btn-outline-success">
              Заказать
            </button>
            <a v-if="reportUrl" class="btn btn-outline-info ml-3" :href="reportUrl" target="_blank">
              <i class="bi-download"></i>
              Скачать
            </a>
          </div>
        </div>
      </form>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { Processing } from "@/components/common";
import { apiClientConfig } from "@/configs";
import { createReportManager } from "@/utils/di";
import { DatePicker } from "v-calendar";

export default {
  name: "NdflReport",
  components: {
    DatePicker,
    Processing
  },
  data() {
    return {
      year: new Date().getFullYear() - 1,
      reportUrl: null,
      processing: false
    };
  },
  computed: {
    years() {
      const startYear = 2020;
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    }
  },
  methods: {
    handleSelect(e){
      this.year = e.target.value;
    },
    async submitForm() {
      this.processing = true;
      this.reportUrl = null;
      try {
        const manager = await createReportManager();
        const urlResult = await manager.exportNdflReport({ year: this.year });
        this.reportUrl = apiClientConfig.baseUrl + urlResult.url;
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>
