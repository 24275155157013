<template>
  <div class="project-documents">
    <div class="card-subtitle mb-3">Проекты</div>
    <paginated-list :count="collection.count"
                    :total-count="collection.totalCount"
                    :data-loading="collection.loading"
                    :data-loading-lock="true"
                    @update="loadData"
    >
      <div v-for="item in collection.items" class="row my-3">
        <div class="col-12 col-md-5 col-lg-4">
          <div>{{ item.project.name }}</div>
          <div class="text-muted">№ {{ item.agreements[0].agreementNumber }}</div>
        </div>
        <div class="col-12 col-md-7 col-lg-8">
          <div v-for="agreement in item.agreements" class="my-1 download-link">
            <a v-if="isInvestment(agreement.type)" :href="agreement.agreementUrl" target="_blank">
              <i class="bi-file-arrow-down"></i>
              Агентское поручение на заключение сделки
            </a>
            <div v-if="isInvestmentAccept1(agreement.type)" class="agreement">
              <a :href="agreement.agreementUrl" target="_blank" class="agreement__document">
                <i class="bi-file-arrow-down"></i>
                Акцепт инвестиционного предложения
              </a>
              <a :href="agreement.agreementSignUrl" target="_blank" class="agreement__sign">
                <i class="bi-file-arrow-down pl-3"></i>
                ЭЦП
              </a>
            </div>
            <a v-if="isInvestmentAgreement(agreement.type)" :href="agreement.agreementUrl" target="_blank">
              <i class="bi-file-arrow-down"></i>
              Договор управления займом
            </a>
            <div v-if="isInvestmentAccept2(agreement.type)" class="agreement">
              <a :href="agreement.agreementUrl" target="_blank" class="agreement__document">
                <i class="bi-file-arrow-down"></i>
                Акцепт договора управления займом
              </a>
              <a :href="agreement.agreementSignUrl" target="_blank" class="agreement__sign">
                <i class="bi-file-arrow-down pl-3"></i>
                ЭЦП
              </a>
            </div>
            <a v-if="isSale(agreement.type)" :href="agreement.agreementUrl" target="_blank">
              <i class="bi-file-arrow-down"></i>
              Агентское распоряжение на продажу инвестиции на вторичном рынке
            </a>
            <a v-if="isPurchase(agreement.type)" :href="agreement.agreementUrl" target="_blank">
              <i class="bi-file-arrow-down"></i>
              Агентское распоряжение на покупку инвестиции на вторичном рынке
            </a>
            <a v-if="isOther(agreement.type)" :href="agreement.agreementUrl" target="_blank">
              <i class="bi-file-arrow-down"></i>
              Справка НДФЛ
            </a>
          </div>
          <div v-if="item.suretyAgreement.length > 1">
            <div v-for="(agreement, index) in item.suretyAgreement" :key="index" class="my-1 download-link">
              <a :href="agreement">
                <i class="bi-file-arrow-down"></i>
                Договор поручительства {{index + 1}}
              </a>
            </div>
          </div>
          <div v-else-if="item.suretyAgreement.length === 1" class="my-1 download-link">
            <a :href="item.suretyAgreement[0]" class="agreement">
              <i class="bi-file-arrow-down"></i>
              Договор поручительства
            </a>
          </div>
        </div>
      </div>
    </paginated-list>
  </div>
</template>

<script>
import {DataCollection, PaginatedList} from "@/components/common";
import {AgreementType} from "@/utils/api-client";
import {createAgreementProvider} from "@/utils/di";

export default {
  name: "ProjectDocuments",
  components: {
    PaginatedList
  },
  data() {
    return {
      collection: new DataCollection(),
    };
  },
  methods: {
    async loadData(page, perPage) {
      this.collection.startLoading();
      try {
        const provider = await createAgreementProvider();
        const collection = await provider.getList(page, perPage);
        this.collection.setCollection(collection);
      } finally {
        this.collection.finishLoading();
      }
    },
    isInvestment(type) {
      return type === AgreementType.INVESTMENT_OFFER;
    },
    isInvestmentAccept1(type) {
      return type === AgreementType.INVESTMENT_ACCEPT_1;
    },
    isInvestmentAgreement(type) {
      return type === AgreementType.INVESTMENT_AGREEMENT_1;
    },
    isInvestmentAccept2(type) {
      return type === AgreementType.INVESTMENT_ACCEPT_2;
    },
    isSale(type) {
      return type === AgreementType.SALE_ORDER;
    },
    isPurchase(type) {
      return type === AgreementType.PURCHASE_ORDER;
    },
    isOther(type) {
      return type === AgreementType.OTHER;
    },
    cleanSuretyAgreement(suretyAgreement) {
      console.log(suretyAgreement.filter(() => true));
      return suretyAgreement.filter(() => true)
    }
  }
}
</script>
<style lang="scss">
.agreement {
  display: flex;

  &__document {
    border-right: 0.125rem solid #ecedef;
    padding-right: 1.25rem;
  }

  &__sign {
    padding-left: 2.5rem
  }
}

</style>
